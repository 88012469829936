.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url(./images/loader.gif) 50% 50% no-repeat
    rgba(225, 225, 225, 0.5);
  opacity: 1;
}
